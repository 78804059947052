<template>
	<router-link v-bind="$props" :to="computedTo" @click.native="$emit('click')"><slot /></router-link>
</template>
<script>
	export default {
		props: {
			to: {},
			tag: {},
			// replace: {},
			// append: {},
			// activeClass: {},
			exact: {},
			// event: {},
			// exactActiveClass: {},
			// ariaCurrentValue: {},
		},
		computed:{
			computedTo(){
				let to = this.to;

				if(typeof to==="object"){

				// }
				// if(to.params){
					let params = to.params;
					to.params = Object.assign({}, params ? to.params : {}, {lang:this.$i18n.locale});
				}
				// console.log(to, typeof to, this.$i18n.locale);
				return to;
			}
		}
	};
</script>
